











































import { Component, Vue } from 'nuxt-property-decorator';
import { PortalTarget } from 'portal-vue';

import { state as revealState } from '@/components/animate/SiteReveal/SiteRevealState';

@Component({
  components: {
    PortalTarget,
  },
})
export default class DefaultLayout extends Vue {
  head () {
    return {
      meta: [{ hid: 'og:url', property: 'og:url', content: `${this.$config.baseUrl}${this.$nuxt.$route.path}` }],
    };
  }

  get siteIsReady () {
    return revealState.siteIsReady;
  }
}
