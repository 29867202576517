import { render, staticRenderFns } from "./recaptcha-form.vue?vue&type=template&id=59414aad&"
import script from "./recaptcha-form.vue?vue&type=script&lang=ts&"
export * from "./recaptcha-form.vue?vue&type=script&lang=ts&"
import style0 from "./recaptcha-form.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Input: require('/tmp/build_26d884fa/src/components/forms/Input.vue').default})
